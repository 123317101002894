import React from "react";
import { graphql, Link } from "gatsby";
import { css } from "@emotion/core";
import Layout from "../components/layout";

export default ({ data }) => (
  <Layout>
    <div>
      <h1
        css={css`
          display: inline-block;
          border-bottom: 1px solid;
          text-align: center;
          color: inherit;
        `}
      >
        Search
      </h1>
      <br />
      <br />
      <Link
        to={`/search2`}
        css={css`
          text-decoration: none;
          color: inherit;
          &:hover {
            text-decoration: underline;
            color: #0080c0;
          }
        `}
      >
        <h5
          css={css`
            color: #0080c0;
            text-decoration: underline;
          `}
        >
          Dark Theme
        </h5>
      </Link>
      <br />
      <br />
    </div>
  </Layout>
);

export const query = graphql`
query {
  allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 1000) {
    totalCount
    edges {
      node {
        excerpt(pruneLength: 250)
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          writer
        }
      }
    }
  }
}
`;


